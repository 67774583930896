<template>
  <div
    class="row"
    v-if="
      (field.type != 'translations') &
      !(field.hide & !form.formData[field.name] & !form.active)
    "
  >
    <dd :class="size_name" v-if="field.title">
      {{ field.title }}
      <div v-if="field.translate && field.type == 'CKInput' && form.active">
        <div v-for="lang in languages" :key="lang.language">
          <CButton
            variant="outline"
            color="secondary"
            :pressed="lang.language == trans_current_lang"
            v-on:click="TransSetLang(lang.language)"
          >
            <CIcon :content="lang.flag" /> {{ lang.title }}
          </CButton>
        </div>
      </div>
    </dd>

    <dt :class="size_value" v-if="field.children">
      <div class="row">
        <div
          v-for="(f, index) in field.children"
          :key="index"
          :class="'col-md-' + f.size"
        >
          <ADVField
            v-if="f.enabled != false"
            :field="f"
            :form="form"
            v-on="$listeners"
          />
        </div>
      </div>
    </dt>
    <dt :class="size_value" v-else-if="field.type == 'CSelect'">
      <span v-if="!form.active || field.readonly">{{
        GetSelValue(
          form.formData[field.name],
          form.formDataOptions[field.options]
        )
      }}</span>

      <CVSelect
        v-if="form.active && !field.readonly"
        @input="setSelected"
        @update:value="setSelected"
        :value="form.formData[field.name]"
        :options="form.formDataOptions[field.options]"
        :is-valid="IsValid(field.name)"
        :invalid-feedback="GetError(field.name)"
        :description="field.description"
      />
    </dt>

    <dt
      :class="size_value"
      v-else-if="field.translate && field.type == 'CKInput'"
    >
      <span
        v-if="!form.active || field.readonly"
        v-html="form.formData[field.translate][$i18n.locale][field.name]"
      />
      <div v-if="form.active && !field.readonly">
        <ckeditor
          :editor="CkEditor"
          v-model="
            form.formData[field.translate][trans_current_lang][field.name]
          "
        ></ckeditor>
      </div>
    </dt>

    <dt :class="size_value" v-else-if="field.type == 'CInputCheckbox'">
      <span v-if="!form.active || field.readonly">
        {{ GetChkValue(form.formData[field.name], CInputCheckbox_options) }}
      </span>
      <div v-if="form.active && !field.readonly">
        <div v-if="field.search">
          <CInput
            horizontal
            v-model="CInputCheckbox_search"
            placeholder="cerca..."
          >
            <template #append-content
              ><CIcon name="cil-magnifying-glass" />
            </template>
          </CInput>
        </div>
        <CInputCheckbox
          v-for="(option, optionIndex) in CInputCheckbox_search_options"
          :key="optionIndex"
          :label="option.label"
          :value="option.value"
          :checked.sync="option.checked"
          :inline="true"
        />
      </div>
    </dt>

    <dt :class="size_value" v-else-if="field.type == 'CInputCheckboxB'">
      <span v-if="!form.active || field.readonly">
        {{ form.formData[field.name] ? field.label : "" }}
      </span>
      <div v-if="form.active && !field.readonly">
        <CInputCheckbox
          :label="field.label"
          :checked.sync="form.formData[field.name]"
          :inline="true"
        />
      </div>
    </dt>

    <dt :class="size_value" v-else-if="field.type == 'CInputRadioGroup'">
      <span v-if="!form.active || field.readonly">
        {{
          GetSelValue(
            form.formData[field.name],
            form.formDataOptions[field.options]
          )
        }}
      </span>
      <div v-if="form.active && !field.readonly">
        <CInputRadioGroup
          :options="form.formDataOptions[field.options]"
          :checked.sync="form.formData[field.name]"
          :inline="true"
          description="Ciao a tutti"
          invalid-feedback="Prova"
        />
      </div>
    </dt>

    <dt :class="size_value" v-else-if="field.translate">
      <span v-if="!form.active || field.readonly">{{
        form.formData[field.translate][$i18n.locale][field.name]
      }}</span>
      <div v-if="form.active && !field.readonly">
        <div v-for="(lang, index) in languages" :key="lang.language">
          <CInput
            :description="
              index == languages.length - 1 ? field.description : null
            "
            horizontal
            v-model="form.formData[field.translate][lang.language][field.name]"
            :is-valid="
              IsValid(field.translate + '.' + lang.language + '.' + field.name)
            "
            :invalid-feedback="
              GetError(field.translate + '.' + lang.language + '.' + field.name)
            "
          >
            <template #prepend-content><CIcon :content="lang.flag" /></template>
          </CInput>
        </div>
      </div>
    </dt>
    <dt :class="size_value" v-else>
      <span v-if="!form.active || field.readonly">{{
        form.formData[field.name]
      }}</span>
      <CInput
        v-if="form.active && !field.readonly"
        :description="field.description"
        horizontal
        v-model="form.formData[field.name]"
        :type="field.type == 'CDate' ? 'date' : null"
        :is-valid="IsValid(field.name)"
        :invalid-feedback="GetError(field.name)"
      />
    </dt>
  </div>
  <!-- <div
    v-else-if="
      (field.type == 'translations') &
      !(field.hide & !field.value & !form.active)
    "
  >
    <CTabs>
      <CTab
        v-for="key in Object.keys($i18n.messages)"
        :key="key"
        :active="$i18n.locale == key"
      >
        <template slot="title"> <CIcon name="cil-list" /> {{ key }} </template>
        <div class="bd-example">
          <div class="row">
            <div
              v-for="(tfield, tindex) in field.childs"
              :key="tindex"
              :class="'col-md-' + tfield.size"
            >
              <dl class="row">
                <dd :class="tfield.type != 'RTInput' ? 'col-sm-4' : 'col-sm-2'">
                  {{ tfield.title }}
                </dd>
                <dt :class="size_value" v-if="tfield.type != 'RTInput'">
                  <span v-if="!form.active">{{
                    field.translations[key][tfield.name]
                  }}</span>
                  <CInput
                    v-if="form.active"
                    :description="tfield.description"
                    horizontal
                    v-model="field.translations[key][tfield.name]"
                    :is-valid="
                      'translations.' + key + '.' + tfield.name in form.errors
                        ? false
                        : null
                    "
                    :invalid-feedback="
                      'translations.' + key + '.' + tfield.name in form.errors
                        ? form.errors.translations[key][tfield.name].join(
                            '<br>'
                          )
                        : null
                    "
                  />
                </dt>
                <dt class="col-sm-10" v-if="tfield.type == 'RTInput'">
                  <span
                    v-if="!form.active"
                    v-html="field.translations[key][tfield.name]"
                  />
                  <div v-if="form.active">
                    <ejs-richtexteditor
                      :height="340"
                      v-model="field.translations[key][tfield.name]"
                    ></ejs-richtexteditor>
                  </div>
                </dt>
              </dl>
            </div>
          </div>
        </div>
      </CTab>
    </CTabs>
  </div> -->
</template>

<script>
import Vue from "vue";
import {
  RichTextEditorPlugin,
  Toolbar,
  HtmlEditor,
} from "@syncfusion/ej2-vue-richtexteditor";
import {
  DropDownTreePlugin,
  AutoCompletePlugin,
} from "@syncfusion/ej2-vue-dropdowns";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CVSelect from "./CVSelect";
import { languages } from "../../config/global";

Vue.use(DropDownTreePlugin);
Vue.use(RichTextEditorPlugin);
Vue.use(AutoCompletePlugin);
var _ = require("lodash");

export default {
  name: "ADVField",
  multiselectOptions: [],
  data() {
    return {
      // value: JSON.parse(JSON.stringify(this.field.value)),
      languages,
      CkEditor: ClassicEditor,
      trans_current_lang: this.$i18n.locale,
      subsize: this.field.subsize || [4, 8],
      CInputCheckbox_search: "",
      CInputCheckbox_options: null,
    };
  },
  created() {
    if (this.field.type == "CInputCheckbox") {
      this.form.formDataOptions[this.field.options].then(
        (e) => {
          this.CInputCheckbox_options = e;
        },
        () => {
          this.CInputCheckbox_options = [];
        }
      );
    }
  },
  props: ["field", "form"],
  computed: {
    CInputCheckbox_search_options() {
      let self = this;
      let res = this.CInputCheckbox_options.reduce(function (map, obj) {
        if (
          self.CInputCheckbox_search == "" ||
          obj.label
            .toLowerCase()
            .indexOf(self.CInputCheckbox_search.toLowerCase()) >= 0
        ) {
          map.push(obj);
        }
        return map;
      }, []);
      return res;
    },

    size_name() {
      return "col-sm-" + this.subsize[0] || 4;
    },
    size_value() {
      return "col-sm-" + this.subsize[1] || 8;
    },
    //     value: {
    //         get(){
    //             return this.field.value
    //         },
    //         set(value){
    //             // this.field.value = value
    //             // return valueù
    //             this.$emit('input', value)
    //             console.log("set value", value)
    //         }
    //     }
  },
  provide: {
    richtexteditor: [Toolbar, HtmlEditor],
  },
  methods: {
    GetError(field) {
      let errors = _.get(this.form.errors, field, null);
      // console.log("GetError", errors)
      return errors ? errors.join("<br>") : null;
    },
    IsValid(field) {
      let errors = this.GetError(field);
      // console.log("IsValid", errors? false: null)
      return errors ? false : null;
    },
    TransSetLang(value) {
      console.log("set trans", value);
      this.trans_current_lang = value;
    },
    setSelected(value) {
      console.log("Field changed", this.field.name, value);
      this.$emit("field_changed", { name: this.field.name, value: value });
      // this.field.value=value
      // console.log("changed", this.field.value)
    },
    GetSelValue(value, options) {
      for (var i = 0; i < options.length; i++) {
        if (options[i].value == value) {
          return options[i].label;
        }
      }
      return value;
    },
    GetChkValue(value, options) {
      let res = [];
      for (var i = 0; i < options.length; i++) {
        if (value.includes(options[i].value)) {
          res.push(options[i].label);
        }
      }
      return res.join(",");
    },
  },
  //   computed: {
  //     value: {
  //       get: function () {
  //         if (this.field.options) {
  //             if (this.field.options.reduce(function (map, obj) {
  //                 map.push(obj.value)
  //                 return map
  //             },[]).includes(this.b_value)){
  //                 return this.b_value;
  //             }else{
  //                 return ""
  //             }
  //         } else {
  //           return this.b_value;
  //         }
  //       },
  //       // setter
  //       set: function (newValue) {
  //         this.b_value = newValue;
  //       },
  //     },
  //   },
  watch: {
    //   "field.value": function (nV, oV) {
    //     console.log("changed value", this.field.name, nV, oV);
    //     // this.field.new_value = nV;
    //     //   console.log(this.field)
    //     // this.$emit("field_changed", { name: this.field.name, value: nV });
    //   },
    //     //     // "field.value": function (newVal, oldVal) {
    //     //     //   console.log("changed field", newVal, oldVal);
    //     //     // },
    // field: {
    //   handler(f) {
    //     // console.log("changed field", f.name, f.value);
    //     console.log("changed field", Date.now());
    //     // console.log("changed field");
    //   },
    //   deep: true,
    // },
    // field: {
    //   deep: true,
    //   handler() {
    //       console.log("watch field")
    //     if (
    //         this.field.options &&
    //       !this.field.options
    //         .reduce(function (map, obj) {
    //           map.push(obj.value);
    //           return map;
    //         }, [])
    //         .includes(this.value)
    //     ) {
    //       this.b_value=""
    //     }
    //   },
    // },
    // "field.options": function (nV, oV) {
    //   console.log("changed options", this.field.name, nV, oV);
    //   if (
    //     this.field.options &&
    //     !this.field.options
    //       .reduce(function (map, obj) {
    //         map.push(obj.value);
    //         return map;
    //       }, [])
    //       .includes(this.value)
    //   ) {
    //     this.value = 0;
    //   }
    // },
    // value: {
    //   deep: true,
    //   handler() {
    //     console.log(
    //       "changed value",
    //       this.field.name,
    //       this.value,
    //       this.field.value
    //     );
    //     if (this.field.translate) {
    //       let res = diff(this.field.value, this.value);
    //       this.field.new_value = res;
    //       this.$emit("field_changed", {
    //         name: "translations." + this.field.name,
    //         value: res,
    //       });
    //     } else {
    //       this.field.new_value = this.value;
    //       this.$emit("field_changed", {
    //         name: this.field.name,
    //         value: this.value,
    //       });
    //     }
    //   },
    // },
  },
  components: {
    CVSelect,
    ckeditor: CKEditor.component,
  },
};
</script>
